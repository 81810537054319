import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import MoralPerson from './partials/MoralPerson';
import PhysicalPerson from './partials/PhysicalPerson';
import PhysicalPersonLineTable from './partials/PhysicalPersonLineTable'
import MoralPersonLineTable from './partials/MoralPersonLineTable'
import PairUseCase from '../../../useCase/Folder/PairUseCase'
import CustomerInfoRecallGateway from '../../../gateway/Folder/CustomerInfoRecallGateway'
import {ENotificationType} from '../notification/ENotificationType'
import Notification from '../notification/Notification'
import SelectedTickMark from '../svg/SelectedTickMark';
import ManuelPersonSelectionLineTable from './partials/ManuelPersonSelectionLineTable';
import { PotentialCustomerInterface, SubscriberInterface } from '../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface';
import { SubscriberScopeEnum } from '../../../domain/CustomerInfoRecall/SubscriberScopeEnum';

interface Props {
  dossierId: string
  subscribers: {[scope: string]: SubscriberInterface}
  potentialCustomers: PotentialCustomerInterface[] | null
  scope: string
  pairing: {[scope: string]: string|null}
  manualSelection: PotentialCustomerInterface|null
  onModify?: (scope: string, username: string|null) => void
  allowCreation?: boolean
}

const PairingTab: React.FC<Props> = ({ dossierId, subscribers, potentialCustomers, scope, onModify, pairing, manualSelection, allowCreation=true }) => {
  const { t } = useTranslation();

  const [success, setSuccess] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string|null>(null)

  const checkPairingAvailability = (username: string|null): boolean => {
    const secondPairing = pairing[scope === SubscriberScopeEnum.NP ? SubscriberScopeEnum.USU : SubscriberScopeEnum.NP];
    if (username && secondPairing === username) {
      setSuccess(false)
      setErrorMessage(t("validation.error.duplicated-pairing"))
      return false;
    }
    return true;
  };

  const handleSelectCustomer = (username: string|null) => {
    if(!checkPairingAvailability(username)) {
      return
    }
    alert('selected')
    const pairUseCase = new PairUseCase(new CustomerInfoRecallGateway())
    pairUseCase.handle({ dossierId: dossierId, username: username, scope: scope, partnerCode: null }).then((response) => {
      if (response) {
        setSuccess(false)
        setSuccess(true)
        if(onModify) {
          onModify(scope, username)
        }
      }
    })
  };

  const handleCustomUserSelection = (potentialUser: PotentialCustomerInterface) => {
    const partnerCode = !allowCreation ? potentialUser.partnerCode : null
    const listedUser = potentialCustomers?.filter(customer => customer.username === potentialUser.username);
    if ((listedUser?.length ?? 0) === 0) {
      if(!checkPairingAvailability(potentialUser.username)) {
        return
      }
      const pairUseCase = new PairUseCase(new CustomerInfoRecallGateway())
      pairUseCase.handle({ dossierId: dossierId, username: potentialUser.username, scope: scope, partnerCode: partnerCode }).then((response) => {
        if (response) {
          setSuccess(true)
          if(onModify) {
            onModify(scope, potentialUser.username)
          }
        }
      })
    } else {
      handleSelectCustomer(potentialUser.username)
    }
  }

  useEffect(() => {
    if (success) {
        setTimeout(() => setSuccess(false), 3000)
    }
    if (errorMessage) {
      setTimeout(() => setErrorMessage(null), 3000)
    }
  }, [success, errorMessage])

  const withCoSubscriber = null !== (subscribers[scope+'_cosous'] ?? null)
  return (
    <div>
      {success &&
        <div className="u-mbm">
          <Notification label={t('validation.pair-success')} type={ENotificationType.success} canClose={false}/>
        </div>
      }
      {errorMessage &&
        <div className="u-mbm">
          <Notification label={errorMessage} type={ENotificationType.error} canClose={false}/>
        </div>
      }
      <h3 className="h5 u-mbs">{t('validation.customer-info-recall')}</h3>
      {
        subscribers[scope] && (
          subscribers[scope].isMoralPerson 
          ? <MoralPerson subscriber={subscribers[scope]}/>
          : <PhysicalPerson subscriber={subscribers[scope]} cosubscriber={subscribers[scope+'_cosous']}/>
        ) || (
          <div className="">
            {t("validation.dismemberment-without-counterpart")}
            {manualSelection && manualSelection.partnerCode && (
            <div className="row">
              <div className="col-md-12">
                <div className="u-mts">
                  <span className='h6'>Partenaire</span> : {manualSelection.partnerName ? `${manualSelection.partnerName} (${manualSelection.partnerCode})` : manualSelection.partnerCode}
                </div>
              </div>
            </div>
          )}
          </div>
        )
      }
      <h3 className="h5 u-mtl">{t('validation.potential-customer-list')}</h3>
        <div className="table-container">
          <table className="table">
            <tbody>
              {allowCreation &&
              <tr key={'new-customer'}>
                <td className="">
                </td>
                <td className="">
                  {t('validation.create-new-customer')}
                </td>
                <td className="">
                </td>
                <td className="">
                </td>
                <td className={"form-control-center"}>
                  { !pairing[scope] ? <SelectedTickMark color={"var(--ink-1)" } /> : (
                  <button type="button"
                          className="button button-primary--outline button--medium form-control-center"
                          onClick={() => handleSelectCustomer(null)}>
                    {t('validation.select-customer')}
                  </button>
                  )}
                </td>
              </tr>
            }
            {potentialCustomers && potentialCustomers.filter((customer) => 
              customer.cosous === withCoSubscriber || !customer.cosous || !withCoSubscriber
            )?.map((potentialCustomer) => (
              <tr key={potentialCustomer.username}>
                {
                  potentialCustomer.isMoralPerson
                    ? <MoralPersonLineTable potentialCustomer={potentialCustomer} selectedUsername={pairing[scope]} onSelectCustomer={handleSelectCustomer}/>
                    : <PhysicalPersonLineTable potentialCustomer={potentialCustomer} selectedUsername={pairing[scope]} onSelectCustomer={handleSelectCustomer}/>
                }
              </tr>
            ))}
              <tr key={"manual-selection"}>
                <ManuelPersonSelectionLineTable potentialCustomer={manualSelection} onSelectCustomerByCode={handleSelectCustomer} selectedUsername={pairing[scope]} onSelectCustomer={handleCustomUserSelection} />
              </tr>
              {manualSelection && (
                <tr key={"manual-selection-second"}>
                  <ManuelPersonSelectionLineTable potentialCustomer={null} onSelectCustomerByCode={handleSelectCustomer} selectedUsername={null} onSelectCustomer={handleCustomUserSelection} />
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </div>
  );
};

export default PairingTab;
