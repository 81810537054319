import React from 'react'
import {useTranslation} from 'react-i18next'

import InputModalWithControl from '../../modal/InputModalWithControl';
import CustomerInfoRecallGateway from '../../../../gateway/Folder/CustomerInfoRecallGateway';
import SearchCustomerInfoRecallUseCase from '../../../../useCase/Folder/SearchCustomerInfoRecallUseCase';
import CustomerLineTable from './CustomerLineTable';
import { PotentialCustomerInterface } from '../../../../domain/CustomerInfoRecall/CustomerInfoRecallGatewayInterface';

interface Props {
  potentialCustomer: PotentialCustomerInterface|null;
  onSelectCustomerByCode: (username: string|null) => void;
  onSelectCustomer: (potentialUser: PotentialCustomerInterface) => void;
  selectedUsername: string|null;
}

const ManuelPersonSelectionLineTable: React.FC<Props> = ({potentialCustomer, onSelectCustomerByCode, onSelectCustomer, selectedUsername}) => {
  const {t} = useTranslation()

  const [showModal, setShowModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string|null>(null);

  const handleConfirm = (data: any) => {
    const searchCustomerInfoRecallUseCase = new SearchCustomerInfoRecallUseCase(new CustomerInfoRecallGateway())
    searchCustomerInfoRecallUseCase.handle(data).then((response) => {
      if (response !== null) {
        onSelectCustomer(response)
        handleClose()
        setErrorMessage(null)
      } else {
        setErrorMessage(t('validation.error.customer-not-found'))
      }
    })
  };

  const handleClose = () => {
    setShowModal(false)
  };
  const blockModal = (showModal &&
  <InputModalWithControl 
    onConfirm={handleConfirm}
    onClose={handleClose}
    isRequired={true}
    value={selectedUsername || ''}
    message={t('validation.enter-customer-code-title')}
    placeholder={"ex: S00012"}
    errorMessage={errorMessage}
    captionYes={t('validation.search')}
    captionNo={t('modal.select.no')}
  />
  );
  const blockCustomer = (
    null !== potentialCustomer ? <CustomerLineTable selectedUsername={potentialCustomer?.username ?? ''} potentialCustomer={potentialCustomer} onSelectCustomer={onSelectCustomerByCode}/> : (
    <>
      <td className="" />
      <td className="">
        {t('validation.select-customer-by-code')}
      </td>
      <td className="" />
      <td className="" />
      <td className={"form-control-center"}>
        <button type="button"
                className="button button-primary--outline button--medium form-control-center"
                onClick={() => setShowModal(true)}>
          {t('validation.select-customer')}
        </button>
      </td>
    </>
  ));


  return <>{blockModal}{blockCustomer}</>;
}

export default ManuelPersonSelectionLineTable
